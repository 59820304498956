import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import KomIGang from './KomIGang.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader title="Introduksjon" mdxType="PageHeader"></PageHeader>
    <div style={{
      margin: '3rem 0'
    }}>
  <img src={KomIGang} width="100%" alt="" />
    </div>
    <h3 {...{
      "id": "fordeler-med-et-designsystem"
    }}>{`Fordeler med et designsystem:`}</h3>
    <ul>
      <li parentName="ul">{`Mindre dobbeltarbeid, raskere ut i produksjon og enklere skalering.`}</li>
      <li parentName="ul">{`Mye av arbeidet med Universell Utforming kommer med gratis`}</li>
      <li parentName="ul">{`Én kilde til sannhet gir en helhetlig brukeropplevelse på tvers av våre produkter, designjusteringer når alle flater raskt.`}</li>
      <li parentName="ul">{`Bedre kunnskapsdeling ved å spille hverandre gode - vi kommer lenger sammen.`}</li>
    </ul>
    <h2 {...{
      "id": "kom-i-gang"
    }}>{`Kom i gang`}</h2>
    <p>{`Aller først anbefaler vi at du går inn på innstillinger ved å trykke på hjulet øverst til høyre i toppmenyen. Det lar deg si hva slags informasjon som er interessant for deg, og hva slags format du ønsker den på.`}</p>
    <p>{`Om du er helt ny i Entur, eller til designsystemet, ta en titt på kom i gang-sidene `}<a parentName="p" {...{
        "href": "/kom-i-gang/for-utviklere/komponentbibliotek"
      }}>{`for utviklere`}</a>{` og `}<a parentName="p" {...{
        "href": "/kom-i-gang/for-designere/designprosess"
      }}>{`for designere`}</a>{`.`}</p>
    <p>{`Lykke til!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      